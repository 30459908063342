import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section className="flex items-center">
      <Section.Content className="font-mono">
        <h1 className="text-6xl font-bold mb-4">404</h1>
        <h4 className="text-4xl text-center">Page Not Found</h4>
      </Section.Content>
    </Section>
  </Layout>
)

export default NotFoundPage
